@import "_mixins.less";

html {
   .hidden-desktop {
      display: inherit !important;
   }
   .visible-desktop {
      display: none !important;
   }
   .visible-phone {
      display: inherit !important;
   }
   .hidden-phone {
      display: none !important;
   }
   #responsiveNotifier { 
      border-width: 1px; 
      font-weight: 100;
   }
}
