
#PageBody.Standard1Lane.searchByMapBody #realEstateListings {
   background-color: #fff;
}
#PageBody.Standard1Lane #findAHomeSearchTabs {
   display: none;
}
#PageBody.Standard1Lane #findAHomeSearchNav {
   display: inline-block;
   width: 100%;
   background-color: #fff;
   border-bottom: none;
   margin-left: 0px;
   height: 80px;
}
#PageBody.Standard1Lane #findAHomeSearchNav .navs {
   position: absolute;
   margin: 45px 0px 0px 10px;
   font-size: 16px;
   font-weight: bold;
}
#PageBody.Standard1Lane #findAHomeSearchNav .navs a {
   position: relative;
   color: #000;
   cursor: pointer;
}
#PageBody.Standard1Lane #findAHomeSearchNav a.searchByFeature {
   margin-left: 10px;
}
#PageBody.Standard1Lane #findAHomeSearchNav a.searchByState,
#PageBody.Standard1Lane #findAHomeSearchNav a.searchBySchool {
   margin-left: 23px;
}
#PageBody.Standard1Lane #findAHomeSearchNav .navs a:hover,
#PageBody.Standard1Lane #findAHomeSearchNav .navs a.selected {
   border-bottom: 4px solid #beaf87;
}
#PageBody.searchByMapBody H1 {
   font-size: 11px;
   text-align: left;
   margin-left: -18px;
}
#PageBody.searchByMapBody .searchTab {
   display: none;
}
#PageBody.Standard1Lane .searchHeader {
   position: absolute;
   font-size: 12px;
   font-weight: bold;
   margin: 15px 0px 0px 20px;
}
#PageBody.searchByMapBody .userInformation{
   font-size: 12px;
}
#PageBody.searchByMapBody #nationalmapContainer {
   display: none;
   height: 80px;
}
#PageBody.Standard1Lane .searchByMapContainer {
   border-top: 1px solid #eee;
   width: 100%;
   height: 66px;
}
#PageBody.Standard1Lane .searchByMapContainer .startStateSearchTxt {
   padding-top: 24px;
   border-bottom: none;
}
#listingLaneContainer .wideSitemapLane {
   width: 100%
}
#listingLaneContainer .wideSitemapLane .stateInformationDiv {
   background: none;
}
#listingLaneContainer .wideSitemapLane .stateInformationDiv .stateLink.wide.localAreaLink {
   display: none;
}

/* STATE DETAIL PAGE */
#PageBody.stateMapLane {
   width: 100%
}
#PageBody.stateMapLane .statedetailSitemapLane {
   margin-left: 0px;
   width: 100%;
   margin-top: -4px;
}
#PageBody.stateMapLane .stateMapWrapper,
#PageBody.stateMapLane .stateMapWrapper #listingCountiesDiv,
#PageBody.stateMapLane .stateMapWrapper #listingCitiesDiv {
   width: 100%;
}
#PageBody.stateMapLane .stateMapWrapper #listingCountiesDiv,
#PageBody.stateMapLane .stateMapWrapper #listingCitiesDiv {
   margin: 0px;
}
#PageBody.stateMapLane .stateMapWrapper #listingCitiesDiv {
   margin-top: 15px;
}
#PageBody.Standard2LaneRight.fineAHomeStateDetails {
   display: none;
}
#PageBody.stateMapLane H1 {
   font-size: 11px;
   text-align: left;
   margin-left: -16px;
}
#PageBody.stateMapLane .LaneTitle {
   margin-left: 0px;
}
#PageBody.stateMapLane H1 .stateNameTitle,
#PageBody.stateMapLane .LaneTitle .secondaryLink,
#PageBody.stateMapLane .returnToLocationSearch,
#PageBody.stateMapLane #stateInformationDiv .stateName,
#PageBody.stateMapLane .statedetailSitemapLane #stateInformationDiv .stateImg {
   display: none;
}
#PageBody.stateMapLane .statedetailSitemapLane #stateInformationDiv {
   border-bottom: 1px solid #7f7f7f;
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol1 {
   margin-left: 10px;
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol1 {
   margin-left: 15px;
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol1,
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol2 {
   width: 125px;
   margin-top: 46px;
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol1 a,
#PageBody.stateMapLane #stateInformationDiv .stateInfoCol2 a {
   font-size: 14px;   
}
#PageBody.stateMapLane #stateInformationDiv .stateInfoCell {
   border: none;
   padding: 15px 5px;
}
#PageBody.stateMapLane #stateInformationDiv .mobileStateName {
   display: inline-block !important;
   position: absolute;
   height: 50px;
   width: 320px;
}
#PageBody.stateMapLane #stateInformationDiv .mobileStateName .stateName {
   display: inline-block;
   position: relative;
   margin-top: 15px;
   margin-left: 20px;
   font-size: 12px;
   float: left;
   font-weight: bold;
}
#PageBody.stateMapLane #stateInformationDiv .mobileStateName .backToStateList {
   position: relative;
   margin-right: 15px;
   margin-top: 17px;
   display: inline-block;
   float: right;
}
#PageBody.stateMapLane .searchDiv {
   display: inline-block !important;
   background-color: #a6a5a5;
   height: 65px;
   width: 100%;
}
#PageBody.stateMapLane .searchDiv .searchBtn {
   position: absolute;
   display: inline-block;
   border: 1px solid #ffffff;
   border-radius: 2px;
   background-color: #deddde;
   width: 280px;
   text-align: center;
   margin: 15px 10px;
   padding: 8px 0px;
   font-size: 12px;
   color: #000;
   cursor: pointer;
}
#PageBody.stateMapLane .stateMapWrapper h2.stateDetailTitle  {
   font-weight: bold;
   font-size: 12px;
   margin: 20px 0px 15px 20px;
}
#PageBody.stateMapLane #listingLaneCities,
#PageBody.stateMapLane #listingLaneCounties {
   position: relative;
   margin: 0px 22px 0px 22px;
   column-count: 1;
   column-width: 280px;
   -webkit-column-width: 280px;
   -moz-column-width: 280px;   
   -webkit-column-count: 1;
   -moz-column-count: 1;   
}
#PageBody.stateMapLane #listingLaneCities a,
#PageBody.stateMapLane #listingLaneCounties a {
   line-height: 34px;
   font-size: 11px;
}
#PageBody.stateMapLane #listingLaneCities {
   margin: -24px 0px 15px 20px;
}
#PageBody.stateMapLane .stateMapWrapper #sortLinkContainer {
   display: none;
   margin: 0;
}

/* SEARCH BY PROPERTY FEATURE */
#PageBody.fahPropertyFeature {
   padding-bottom: 0px;
}
#PageBody.fahPropertyFeature .findAHomeBody {
   width: 100%;
   background-color: #f3f3f3;
   margin-top: -4px;
}
#PageBody.fahPropertyFeature h1 {
   font-size: 11px;
   margin-left: -16px;
}
#PageBody.fahPropertyFeature .locationSearchInformation {
   padding: 10px 20px;
   border-bottom: 1px solid #dddddd;
   border-top: 1px solid #dddddd;
}
#PageBody.fahPropertyFeature .locationSearchInformation .info {
   font-size: 12px;
   margin: 0;
}
#PageBody .findAHomeBody #findAHomeLocation #findAHomeSearch {
   width: 100%;
}

#PageBody.fahPropertyFeature #findAHomeLocation {
   background-color: #fff;
}
#PageBody.fahPropertyFeature .findAHomeBody #findAHomeLocation .locationIcon,
#PageBody.fahPropertyFeature .findAHomeBody #findAHomeLocation .findHomeLabel {
   display: none;
}
#PageBody.fahPropertyFeature .findAHomeBody #findAHomeLocation .findHomeLabel {
   display: inline-block !important;
   width: 100%;
   float: left;
   font-weight: bold;
   font-size: 14px;
   margin: 15px 0 20px 0px;   
}
#PageBody.fahPropertyFeature .findAHomeBody #findAHomeLocation .fieldError {
   top: -6px;
   left: -5px;
}
#PageBody.fahPropertyFeature .findAHomeSearchOptions {
   margin: 10px 0 20px 20px;
   width: 300px;
}
#PageBody.fahPropertyFeature .autocompleteContainer.findHomeSearchInputs,
#PageBody.fahPropertyFeature .autocompleteContainer.findHomeSearchInputs .foreground,
#PageBody.fahPropertyFeature .autocompleteContainer.findHomeSearchInputs .background {
   width: 200px;
   margin-left: 0px;
}
#PageBody #findASchoolSearch .findHomeSearchInputs input {
   width: 100%;
}
#PageBody.fahPropertyFeature .findAHomeSearchBtn {
   margin-left: -15px;
}
#PageBody.fahPropertyFeature #searchButton1 {
   width: 45px;
}
#PageBody.fahPropertyFeature .collapsableHeader {
   display: none !important;
}
#PageBody.fahPropertyFeature #propertyEssential,
#PageBody.fahPropertyFeature #propertyAdditional {
   display: inline-block !important;
   width: 100%;
   border: none;
   margin-left: 10px;
}
#PageBody.fahPropertyFeature #propertyAdditional {
   min-height: 0px;
   padding-bottom: 0px;
}
#PageBody.fahPropertyFeature #propertyEssential.collapse {
   display: none !important;   
}
#PageBody.fahPropertyFeature #propertyAdditional {
   margin-left: 0px;
}
#PageBody.fahPropertyFeature #findAHomePropertyDetails {
   border: none;
   margin: 0;
   overflow: hidden;
}
#PageBody.fahPropertyFeature #findAHomePropertyDetails .info {
   display: inline-block !important;
   font-weight: bold;
   font-size: 14px;
   width: 100%;
}
#PageBody.fahPropertyFeature .findHomePropDetails {
   width: 100%;
   margin: 20px 0px 22px 8px;
}
#PageBody.fahPropertyFeature .propBaths,
#PageBody.fahPropertyFeature .propBedrooms {
   float: none;
   padding: 0;
   border: none;
   margin-top: 25px;
}
#PageBody.fahPropertyFeature .propPrice {
   float: none;
   border: none;
   margin-top: 10px;
}
#PageBody.fahPropertyFeature .propBedrooms {
   margin-top: 18px;
}
#PageBody.fahPropertyFeature .propPrice .fieldError {
   top: -10px;
}
#PageBody.fahPropertyFeature .findHomePropDetails .propPrice input[type="text"] {
   width: 92px;
}
#PageBody.fahPropertyFeature #propertyEssential .propPrice .findHomePropLabel,
#PageBody.fahPropertyFeature #propertyEssential .propBaths label,
#PageBody.fahPropertyFeature #propertyEssential .propBedrooms label {
   width: 100%;
   height: 30px;
   text-transform: none;
}
#PageBody.fahPropertyFeature #propertyEssential .selector {
   margin-left: -4px;
   width: 114px;
}
#PageBody.fahPropertyFeature #propertyEssential b,
#PageBody.fahPropertyFeature #propertySize b {
   color: #000;
}
#PageBody.fahPropertyFeature #propertyTypes {
   padding-right: 0px;
   float: none;
   height: 365px;
   border: none;
   width: 100%;
   margin-left: 10px;
}
#PageBody.fahPropertyFeature #propertyTypes .title,
#PageBody.fahPropertyFeature #listingTypes .title {
   display: none;
}
#PageBody.fahPropertyFeature .propTypeLabel {
   margin: 10px 0px;
}
#propertyTypes .propTypeBox {
   margin-left: 0px;
}
#PageBody.fahPropertyFeature #propertySize {
   border: none;
   float: none;
   width: 100%;
   margin: 0;
}
#PageBody.fahPropertyFeature #propertySizeTable {
   margin: 5px 0px 25px 15px;
}
#PageBody.fahPropertyFeature #propertySizeTable .propSizeLabel {
   text-transform: none;
}
#PageBody.fahPropertyFeature #propertySizeTable input[type="text"] {
   width: 92px;
}
#PageBody.fahPropertyFeature #listingTypes {
   width: 100%;
   float: none;
   padding: 0px;
   height: 390px;
   margin-top: 10px;
}
#PageBody.fahPropertyFeature #listingTypes .listingTypeBox {
   margin-left: 0px;
}
#PageBody.fahPropertyFeature #listTypeForSaleRadioDiv, 
#PageBody.fahPropertyFeature #listTypeNewListingsDiv, 
#PageBody.fahPropertyFeature #listTypeOpenHousesRadioDiv {
   display: block;
   margin-top: 14px;
   font-size: 13px;
}
#PageBody.fahPropertyFeature .listingTypeBox .listingTypeRow {
   margin-top: 15px;
}
#PageBody.fahPropertyFeature .findHomeBtm {
   display: inline-block !important;
   width: 100%;
   margin: 0;   
   background-color: #fff;
}
#PageBody.fahPropertyFeature #listTypeNewListingsDiv {
   margin-left: 28px;
}
#PageBody.fahPropertyFeature .StandardBtn.findHomeSearchBtnImg,
#PageBody.fahPropertyFeature .StandardBtn.findHomeClearBtnImg {
   width: 65px;
}
#PageBody.fahPropertyFeature .findHomeBtmSearchBtn {
   width: 100px;
}
#PageBody.fahPropertyFeature .refineTab,
#PageBody.fahSchoolBody .refineTab {
   display: inline-block !important;
   width: 100%;
   box-sizing: border-box;
   padding: 15px;
   border-bottom: 1px solid #dddddd;
   border-top: 1px solid #dddddd;
   cursor: pointer;
}
#PageBody.fahPropertyFeature .refineTab .header,
#PageBody.fahSchoolBody .refineTab .header {
   font-size: 14px;
   font-weight: bold;
   color: #252526;
}
#PageBody.fahPropertyFeature .refineTab .actionImage,
#PageBody.fahSchoolBody .refineTab .actionImage {
   position: relative;
   float: right;
   right: 45px;
   width: 24px;
   height: 16px;
   background: url(/images/findahome/arrow_up.png) 0px 0px  no-repeat;
}
#PageBody.fahPropertyFeature .refineTab.collapse .actionImage,
#PageBody.fahSchoolBody .refineTab.collapse .actionImage {
   width: 24px;
   height: 14px;
   background: url(/images/findahome/arrow_down.png) 0px 0px  no-repeat;
}
#PageBody.fahPropertyFeature #findahomeDropdownContent,
#PageBody.fahSchoolBody #findahomeDropdownContent {
   width: 100%;
   padding: 0;
}
#PageBody.fahPropertyFeature #findahomeDropdownContent #findAHomeSuggestMultipleResult,
#PageBody.fahSchoolBody #findahomeDropdownContent #findAHomeSuggestMultipleResult {
   padding: 0px 15px 15px 15px;
}
#PageBody.fahPropertyFeature #findahomeDropdownContent .multipleResultText,
#PageBody.fahSchoolBody #findahomeDropdownContent .multipleResultText {
   float: none;
   width: 250px;
   font-size: 14px;
   margin-bottom: 15px;
   line-height: 18px;
}
#PageBody.fahPropertyFeature #findahomeDropdownContent .multipleResultColumn,
#PageBody.fahSchoolBody #findahomeDropdownContent .multipleResultColumn {
   width: 100%;
   float: none;   
   font-size: 14px;
}
#PageBody.fahPropertyFeature #findahomeDropdownContent .noResultText,
#PageBody.fahSchoolBody #findahomeDropdownContent .noResultText {
   padding: 15px;   
}

/* SCHOOL SEARCH */
#PageBody.fahSchoolBody {
   padding-bottom: 0px;
}
#PageBody.fahSchoolBody h1 {
   font-size: 11px;
   margin-left: -16px;
}
#PageBody.fahSchoolBody .findAHomeBody {
   width: 100%;
   background-color: #f3f3f3;
   margin-top: -4px;
}
#PageBody.fahSchoolBody .schoolSearchInformation {
   display: none;
}
#PageBody.fahSchoolBody .schoolSearchMobileInformation {
   display: inline-block !important;
   padding: 10px 20px;
}
#PageBody.fahSchoolBody .findASchoolSearchMsg,
#PageBody.fahSchoolBody .locationIcon,
#PageBody.fahSchoolBody .schoolIcon,
#PageBody.fahSchoolBody .locationRequiredInfo {
   display: none;
}
#PageBody.fahSchoolBody .findASchoolMobileSearchMsg {
   display: inline-block !important;
   background-color: #ccc;
   font-size: 16px;
   font-weight: bold;
   width: 100%;
   padding: 10px 0px;
   text-align: center;
}
#PageBody.fahSchoolBody #findASchoolSearch {
   margin: 0;
}
#PageBody.fahSchoolBody #findASchoolSearch .schoolNameField {
   margin-left: -68px;
   margin-top: 10px;
}
#PageBody.fahSchoolBody #findASchoolSearch #schoolName {
   width: 265px;
}
#PageBody.fahSchoolBody #findASchoolSearch .autocompleteContainer.findHomeSearchInputs {
   margin: 10px 0px;
   width: 275px;
} 
#PageBody.fahSchoolBody #findASchoolSearch .findSchoolLabel {
   margin-top: -18px;
}
#PageBody.fahSchoolBody #findASchoolMainSearch {
   margin: 0;
   width: 100%;
   height: 230px;
   background-color: #fff;
}
#PageBody.fahSchoolBody #findASchoolMainSearch #findASchoolSearch {
   position: absolute;
   margin: 30px 0px;
   width: 100%;
}
#PageBody.fahSchoolBody #findASchoolMainSearch #findASchoolSearch .schoolNameSearch,
#PageBody.fahSchoolBody #findASchoolMainSearch #findASchoolSearch .locationSearch {
   margin-left: 20px;   
   width: 300px;   
}
#PageBody.fahSchoolBody .horizontalSeperator {
   margin: 0;
}
#PageBody.fahSchoolBody #findASchoolCriteriaSearch {
   margin: 0;
   background-color: #fff;
   height: 122px;
   padding: 15px 2px;
}
#PageBody.fahSchoolBody #findASchoolCriteriaSearch .schoolSearchCriteria {
   width: 135px;
   height: 65px;
   margin-left: 18px;
}
#PageBody.fahSchoolBody #findASchoolCriteriaSearch .schoolSearchCriteria .selectorField {
   margin-left: 0px;
   margin-top: 25px;
   display: inline-block;
}
#PageBody.fahSchoolBody #findASchoolCriteriaSearch .schoolSearchCriteria .selector {
   width: 120px;
   margin-left: -5px;
}
#PageBody.fahSchoolBody #findASchoolMainSearch .searchActions {
   display: inline-block !important;
   position: absolute;
   margin: 150px 0px;
   width: 100%;
}
#PageBody.fahSchoolBody #findASchoolMainSearch .searchActions .requiredMsg {
   position: relative;
   float: left;
   margin-left: 30px;
}
#PageBody.fahSchoolBody #findASchoolMainSearch #schoolSearchButton1 {
   display: none;
}
#PageBody.fahSchoolBody .searchActions a.findSchoolSearchBtnImg {
   display: inline-block;
   float: right;
   right: 26px;
}
#PageBody.fahSchoolBody .collapsableHeader {
   display: none !important;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch {
   border: none;
   min-height: 595px;
   margin: 0;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .featureList {
   display: inline-block !important;
   border: none;
   height: 500px;
   margin: 15px 0px 0px 5px;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .featureList .featuresLane1,
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .featureList .featuresLane2,
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .featureList .featuresLane3 {
   float: none;
   width: 300px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #findASchoolFeaturesSearch .infoLabel {
   margin-left: 10px;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .searchActions a {
   display: inline-block;
   float: right;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .searchActions a.clearCriteria {
   right: 122px;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .searchActions a.findSchoolSearchBtnImg {
   left: 24px;
}
#PageBody.fahSchoolBody #findASchoolFeaturesSearch .findSchoolBtm {
   display: inline-block;
   float: right;
}

#PageBody.fahSchoolBody .refineTab .actionImage {
   right: 0px;
}

/* SCHOOL RESULTS */
#PageBody.fahSchoolBody #noSchoolSearchResultsSection,
#PageBody.fahSchoolBody #schoolSearchResultsSection {
   margin: 0;
   border: none;
}
#PageBody.fahSchoolBody #schoolSearchResultsSection {
   background-color: #fff;
}
#PageBody.fahSchoolBody #noSchoolSearchResultsSection .actionInformation,
#PageBody.fahSchoolBody #noSchoolSearchResults .noSchoolResult,
#PageBody.fahSchoolBody #noSchoolSearchResultsSection .resultsTitle,
#PageBody.fahSchoolBody #schoolSearchResultsSection .resultsTitle,
#PageBody.fahSchoolBody #schoolSearchResultsSection .actionInformation {
   display: none;
}
#PageBody.fahSchoolBody .resultsTitleMobile {
   display: inline-block !important;
   width: 100%;
   font-weight: bold;
   font-size: 16px;
   text-align: center;
   padding: 10px 0px;
}
#PageBody.fahSchoolBody #noSchoolSearchResults .noSchoolResult:first-child {
   display: inline-block;
   width: 315px;
}
#PageBody.fahSchoolBody #noSchoolSearchResultsSection .actionSection {
   margin: 15px;
   line-height: 22px;
}
#PageBody.fahSchoolBody #noSchoolSearchResultsSection .buttonSection {
   display: none;
}
#PageBody.fahSchoolBody #noSchoolSearchResultsSection .searchActions {
   display: inline-block !important;
   width: 100%;
   margin: 30px 0px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection #uniform-schoolSortTypes {
   width: 205px;
}
#PageBody.fahSchoolBody #schoolSearchResultsSection .schoolResultsAction {
   height: 40px;
}
#PageBody.fahSchoolBody #schoolSearchResultsSection .schoolResultsAction .actionSection {
   margin: 22px 20px;
   float: none;
   font-weight: bold;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolRecord {
   width: 100%;
   height: 225px;
   background: none;
   margin: 10px 0px 0px 0px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane1 {
   position: relative;
   width: 100%;
   height: 85px;
   margin: 10px 0px 0px 20px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane2 {
   position: relative;
   width: 100%;
   margin: 0;
   height: 65px;
   margin: 0px 0px 0px 14px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane3 {
   position: relative;
   width: 100%;
   margin: 0;   
   height: 55px;
   margin: 0px 0px 0px 14px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody .infoLabel {
   margin-left: 8px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolSelectInfo {
   margin: 15px 0px 0px 0px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .horizontalSeperator {
   margin: 5px 0px;
}
#PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .searchHomesBtmSection {
   padding-top: 30px;
}
#findAHomeBySchoolBody #schoolSearchResultsSection .resultsHeader, 
#findAHomeBySchoolBody #noSchoolSearchResultsSection .resultsHeader {
   height: 40px;
}


/* ESPANOL SITE */
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .infoLabel {
   width: 190px;
}
body.esp #PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection #uniform-schoolSortTypes {
   width: 186px;
}
body.esp #findAHomeBySchoolBody #schoolSearchResultsSection .schoolSelectInfo .info {
   font-size: 12px;
   position: absolute;
}
body.esp #PageBody.Standard1Lane #findAHomeSearchNav .navs {
   font-size: 12px;
}
body.esp #PageBody.Standard1Lane #findAHomeSearchNav a.searchByState, 
bod.esp #PageBody.Standard1Lane #findAHomeSearchNav a.searchBySchool {
   margin-left: 10px;
}
body.esp #PageBody.Standard1Lane #findAHomeSearchNav a.searchByFeature {
   margin-left: 0px;
}
body.esp #listTypeForSaleRadioDiv, 
body.esp #listTypeNewListingsDiv, 
body.esp #listTypeOpenHousesRadioDiv {
   margin-left: 0px;
}
body.esp #PageBody.fahSchoolBody #findASchoolCriteriaSearch .schoolSearchCriteria {
   width: 135px;
   height: 74px;
}
body.esp #PageBody.fahSchoolBody #findASchoolCriteriaSearch .schoolSearchCriteria .selectorField {
   margin-left: 0px;
   margin-top: 38px;
}
body.esp #PageBody.fahSchoolBody #findASchoolCriteriaSearch {
   height: 138px;
}
body.esp #PageBody.Standard1Lane .searchByMapContainer .startStateSearchTxt {
   padding-top: 18px;
}
body.esp #PageBody.searchByMapBody .userInformation {
   padding: 0px 15px;
}
body.esp #PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolRecord {
   height: 245px;
}
body.esp #PageBody.fahSchoolBody #findAHomeBySchoolBody #schoolSearchResultsSection .schoolLane1 {
   height: 100px;
}
body.esp #PageBody.Standard1Lane .searchHeader {
   margin: 15px 0px 0px 10px;
}
body.esp #PageBody.stateMapLane #stateInformationDiv .mobileStateName .stateName {
   margin-left: 12px;
}
body.esp #PageBody.stateMapLane #stateInformationDiv .mobileStateName .backToStateList {
   width: 100px;  
}
body.esp #PageBody.stateMapLane .searchDiv .searchBtn {
   font-size: 11px;
}
body.esp #PageBody.fahSchoolBody #findASchoolFeaturesSearch .findSchoolBtm {
   margin-top: 20px;
}

/* FOOTER */
#FooterTopMarkets .FooterRow a {
   line-height: 25px;
}
