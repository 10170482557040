@import "_mixins.less";

#dropdown {
   left: auto;
   width: 96%;
   margin: 0 2%;
}
#dropdownSearchBox {
   background: #999; /* #777 url(/images/mobilesearchboxbg.png) top left repeat-x; */
   box-shadow: 0px 0px 0px 1px #666;
   border: 1px solid #ccc;
   border-radius: 2px;
   box-sizing: border-box;
}
#dropdown .searchText {
   background-color: white;
   height: 40px;
   top: 13px;
   left: 13px;
   width: 64.5%;
   padding-left: 3%;
   margin-right: 1%;
   font-size: 13px;
   border: 1px solid #666;
   box-shadow: inset 0 0 10px #bbb;
   box-sizing: border-box;
}
body.esp #searchButton:before {
   content: "Buscar C21";
}
body.esp a#searchButton:hover,
body.esp a#searchButton,
a#searchButton {
   right: 13px;
   padding: 0px 5px;
   top: 13px;
   display: inline-block;
   height: 40px;
   width: 28%;
   background: @c21gold;
   line-height: 39px;
   text-align: center;
   overflow: hidden;
   color: #333;
   margin: 0;
   font-size: 13px;
   background: #ffd813;
   border: 1px solid rgba(0,0,0,0.4);
   text-shadow: 0px 1px 2px #fff;
   text-transform: uppercase;
   font-weight: bold;
   box-sizing: border-box;
}
body.esp a#searchButton:hover,
#searchButton:hover {
   background: @c21gold;
}
#searchButton:before {
    content: "Search C21";
}
#searchReset {
   display: none !important;
}
#dropdownResults {
   width: 100%;
}
#dropdownResults #searchCriteria {
   display: none;
}
#dropdownResultsBottom,
#dropdownResultsTop,
#dropdownContentTop,
#dropdownContentRepeat {
   display: none;
}
#dropdownRepeat {
   background: #eee;
   width: 100%;
}
#dropdownContent {
   padding: 0;
   border: 1px solid #777;
   background: #eee;
}
#innerSearch {
   width: 100%;
}
.locationResultsDiv,
.propertyResultsDiv,
.officeResultsDiv,
.agentResultsDiv {
   width: 100%;
}
.freetextsearchSectionHeader {
   float: none;
   margin: 0 0 0 9px;
   position: relative;
   top: 5px;
   width: auto;
}
.freeTxtResults {
   float: none;
}   
.freeTxtResults.location {
   width: auto;
   margin-left: 10px;
}
.locationRow .locationCellWrapper {
   display: none;
}
.officeResultsInnerDiv,
.agentResultsInnerDiv,
.propertyResultsInnerDiv {
   width: auto;
}
.freeTxtResults.properties {
   width: auto;
   margin: 10px;
}
.freeTxtResults.properties .cell .propMLS {
   display: none;
}
.freeTxtResults.agents {
   width: auto;
   margin: 10px;
}
.freeTxtResults.offices {
   width: auto;
   margin: 10px;
}
.locationResultsDiv.parentHover,
.propertyResultsDiv.parentHover,
.officeResultsDiv.parentHover,
.agentResultsDiv.parentHover {
   background: inherit;
}

.showLessLinkDiv,
.moreLinkDiv.location, .moreLinkDiv.property, .moreLinkDiv.office, .moreLinkDiv.agent {
   position: absolute;
   right: 5px;
   bottom: auto;
   top: 5px;
   margin: 0;
}
.viewAllLinkDiv {
   display: none;
   bottom: auto;
   top: 5px;
}
.suggestMessageDropDown {
   width: auto;
}
.freetextsearchLabelNoData {
   padding-left: 9px;
}

/**
 * Office/Agent/Property Cards
 * Yes, they are all off by a few pixels from each other in the original design
 * I have to correct for that in mobile, since it's visible with an only 5 pixel
 * margin.  Don't yell at me, yell at the original CSS writer. ;)  -Scott
 */
.FTSCenterLaneCard .CardThumb {
   display: none;
}
.FTSCenterLaneCard.agentCard {
   margin-left: -220px;
}
.FTSCenterLaneCard.propertyCard {
   margin-left: -326px;
}
.FTSCenterLaneCard.officeCard {
   margin-left: -322px;
}
.FTSCenterLaneCard .propertyDetailsSectionHeader {
   left: 334px;
   top: 5px;
}
.FTSCenterLaneCard.agentCard  .propertyDetailsSectionHeader {
   left: 228px;
}
.FTSCenterLaneCard.officeCard  .propertyDetailsSectionHeader {
   left: 330px;
}
.FTSOfficeCardHeader.office {
   margin-top: 20px;
}
.FTSCenterLaneCardHeader.agent {
   margin-top: 5px;
   width: 270px;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}  
#searchSuggestDisambiguation #dropDownNotification, 
#searchSuggestNoResult #dropDownNotification {
   font-size: 12px;
}
.FTSCenterLaneCard.agentCard .agentdesignations {
   max-width: 250px;
}

/**
 * HOLY OVERSPECIFICATION BATMAN!! 
 * I wish these weren't over-specified in the original CSS... 
 * But they are, and this is an additive interim solution, so enjoy. 
 */
.FTSDropdownBackground .topSuggest {
   width: auto;
}
.FTSDropdownBackground .topSuggest .forSaleSuggest,
.FTSDropdownBackground .topSuggest .forRentSuggest,
.FTSDropdownBackground .topSuggest .c21AgentsSuggest,
.FTSDropdownBackground .topSuggest .c21OfficesSuggest {
   width: 100%;
   border: 0;
   overflow: hidden;
   float: none;
   height: 29px;
}
.FTSDropdownBackground .centerSuggest {
   width: auto;
}
.FTSDropdownBackground .bottomSuggest {
   width: auto;
}

.FTSDropdownBackground .topSuggest .forSaleSuggest .forSaleImage,
.FTSDropdownBackground .topSuggest .forRentSuggest .forRentImage,
.FTSDropdownBackground .topSuggest .c21AgentsSuggest .c21AgentsImage,
.FTSDropdownBackground .topSuggest .c21OfficesSuggest .c21OfficesImage {
   display: none;
}
.FTSDropdownBackground .centerSuggest .openHousesSuggest .openHousesImage,
.FTSDropdownBackground .centerSuggest .newlyListedSuggest .newlyListedImage,
.FTSDropdownBackground .centerSuggest .priceReducedSuggest .priceReducedImage,
.FTSDropdownBackground .centerSuggest .foreclosuresSuggest .foreclosuresImage {
   display: none;
}
.FTSDropdownBackground .topSuggest .forSaleSuggest .forSaleInfo, 
.FTSDropdownBackground .topSuggest .forRentSuggest .forRentInfo, 
.FTSDropdownBackground .topSuggest .c21AgentsSuggest .c21AgentsInfo, 
.FTSDropdownBackground .topSuggest .c21OfficesSuggest .c21OfficesInfo {
   text-align: left;
   left: 10px;
   top: 5px;
}

.FTSDropdownBackground .centerSuggest .openHousesSuggest,
.FTSDropdownBackground .centerSuggest .newlyListedSuggest,
.FTSDropdownBackground .centerSuggest .priceReducedSuggest,
.FTSDropdownBackground .centerSuggest .foreclosuresSuggest {
   height: 27px;
   width: 50%;
}
.FTSDropdownBackground .centerSuggest .openHousesSuggest .openHousesInfo,
.FTSDropdownBackground .centerSuggest .newlyListedSuggest .newlyListedInfo,
.FTSDropdownBackground .centerSuggest .priceReducedSuggest .priceReducedInfo,
.FTSDropdownBackground .centerSuggest .foreclosuresSuggest .foreclosuresInfo {
   left: 10px;
   top: 5px;
}
.FTSDropdownBackground .centerSuggest .foreclosuresSuggest .foreclosuresInfo .infoCount, 
.FTSDropdownBackground .centerSuggest .newlyListedSuggest .newlyListedInfo .infoCount, 
.FTSDropdownBackground .centerSuggest .priceReducedSuggest .priceReducedInfo .infoCount, 
.FTSDropdownBackground .centerSuggest .openHousesSuggest .openHousesInfo .infoCount {
   display: inline;
}
.FTSDropdownBackground .centerSuggest .foreclosuresSuggest .foreclosuresInfo .infoLabel, 
.FTSDropdownBackground .centerSuggest .newlyListedSuggest .newlyListedInfo .infoLabel, 
.FTSDropdownBackground .centerSuggest .priceReducedSuggest .priceReducedInfo .infoLabel, 
.FTSDropdownBackground .centerSuggest .openHousesSuggest .openHousesInfo .infoLabel {
   display: inline;
}
.FTSDropdownBackground .bottomSuggest .viewLocalArea {
   width: auto;
   padding: 10px 5px;
}
.FTSDropdownBackground .bottomSuggest .viewLocalArea .localAreaImage {
   display: none;
}
.FTSDropdownBackground .bottomSuggest .viewLocalArea .localAreaInfo {
   margin-left: 0;
}

/* Remove local area info */
.FTSDropdownBackground {
   height: 174px;
}
.FTSDropdownBackground .bottomSuggest {
   display: none;
}
